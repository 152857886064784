<template>
  <teleport to="body">
    <UseFocusTrap v-if="isOpen" v-show="renderIfHidden || isOpen" :options="{ immediate: true }">
      <div class="modal-overlay z-[1002]" @click="hide">
        <div role="dialog" aria-modal="true" class="max-h-full p-0 md:p-12 grid grid-cols-1 place-content-center">
          <div :class="['modal bg-white md:rounded-md overflow-hidden w-screen md:h-auto max-h-full max-w-full', props.width, { fullscreen: props.fullscreen } ]" @click.stop>
            <div class="relative">
              <button aria-label="Close" class="cursor-pointer focus:outline-none  absolute right-0 top-0 h-full flex justify-items-center p-5" @click="hide">
                <UiIcon src="/svg/icons/cancel.svg" width="34" height="34" alt="close" class="fill-neutral-500 rotate-180 -ml-[3px]" />
              </button>
              <div class="border-b border-neutral-200 text-lg font-bold py-6 px-16 min-h-[70px] text-center">
                <template v-if="$slots.head">
                  <slot name="head" />
                </template>
              </div>
            </div>
            <div class="px-6 md:px-8 py-8 h-full overflow-auto">
              <slot />
            </div>
            <div v-if="$slots.foot" class="sticky bg-white bottom-0">
              <slot name="foot" />
            </div>
            <div v-if="$slots.footerClose" class="sticky bg-white bottom-0" @click="hide">
              <slot name="footerClose" />
            </div>
          </div>
        </div>
      </div>
    </UseFocusTrap>
  </teleport>
  <slot name="trigger" />
</template>

<script setup>
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
import { useModalWindowStore } from '~~/stores/modalWindow'

const emit = defineEmits(['shown', 'hidden'])

const modalWindowStore = useModalWindowStore()

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  width: {
    type: String,
    default: 'md:w-[40rem]'
  },
  fullscreen: {
    type: Boolean,
    default: false
  },
  renderIfHidden: {
    type: Boolean,
    default: true
  }
})

const isOpen = ref(props.open)

const show = () => {
  isOpen.value = true
  document.body.classList.add('modal-open')
  window.history.pushState({ modalOpen: true, hash: '' }, '')
  modalWindowStore.isOpen = true
  emit('shown')
}
const hide = () => {
  modalWindowStore.isOpen = false
  isOpen.value = false
  document.body.classList.remove('modal-open')
  emit('hidden')
}

watch(() => props.open, (newValue) => {
  isOpen.value = newValue
  if (isOpen.value === true) {
    show()
  } else {
    document.body.classList.remove('modal-open')
  }
})

if (import.meta.client) {
  window.addEventListener('popstate', () => {
    hide()
  })
}

defineExpose({
  show,
  hide,
  isOpen
})
</script>

<style lang="scss">
body.modal-open{
  @apply overflow-hidden;
}
.modal-overlay {
  @apply fixed inset-x-0 inset-y-0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  .modal{
    @apply fixed md:relative inset-x-0 inset-y-0 md:inset-x-auto md:inset-y-auto flex flex-col;
  }
}
.fullscreen {
  width: 100vw;
  height: 100vh;
  display: flex !important;
  flex-direction: column;
}
</style>
